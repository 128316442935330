import React from 'react';
import _ from 'lodash';

import { Layout } from '../components/index';
import { safePrefix, htmlToReact, markdownify, formatGatsbyDate } from '../utils';

export default class Episode extends React.Component {
	render() {
		let sandboxedEmbedCode = _.get(this.props, 'pageContext.frontmatter.episode_embed_code');
		if (sandboxedEmbedCode?.indexOf('sandbox=') < 0) {
			sandboxedEmbedCode = sandboxedEmbedCode.replace(
				'<iframe ',
				'<iframe sandbox="allow-scripts" '
			);
		}

		return (
			<Layout {...this.props}>
				<div id="wrapper">
					<section id="main" className="wrapper">
						<div className="inner">
							<h1 className="major">
								#{_.get(this.props, 'pageContext.frontmatter.number')} -{' '}
								{_.get(this.props, 'pageContext.frontmatter.title')}
							</h1>
							{_.get(this.props, 'pageContext.frontmatter.content_image') && (
								<span className="image fit">
									<img
										src={safePrefix(_.get(this.props, 'pageContext.frontmatter.content_image'))}
										alt={_.get(this.props, 'pageContext.frontmatter.content_image_alt')}
									/>
								</span>
							)}
							<p>{formatGatsbyDate(_.get(this.props, 'pageContext.frontmatter.date'))}</p>

							{markdownify(_.get(this.props, 'pageContext.frontmatter.excerpt'))}

							<div
								style={{ margin: '1.5em 0' }}
								dangerouslySetInnerHTML={{
									__html: sandboxedEmbedCode,
								}}
							/>

							{_.get(this.props, 'pageContext.html') && <h2>Full Transcript</h2>}
							{htmlToReact(_.get(this.props, 'pageContext.html'))}
						</div>
					</section>
				</div>
			</Layout>
		);
	}
}
